import React from "react";

import nft from "./images/nft.png";

function App() {
  const [C2, setC2] = React.useState<string>("0");
  const [C4, setC4] = React.useState<string>("0");

  const [W2, setW2] = React.useState<string>("0");
  const [W4, setW4] = React.useState<string>("0");

  return (
    <div className="flex flex-col">
      <p className="montserrat font-bold mx-auto text-2xl mt-10">
        The Seer Point Calculator
      </p>
      <img
        src={nft}
        alt="nft"
        className="w-52 rounded-full mx-auto mt-5 border-2 border-black"
      />
      <p className="montserrat mx-auto w-11/12 md:w-6/12 text-center mt-5">
        The Seer is the first predict-to-earn NFT project in Avalanche. Collect
        points and climb up the leaderboard by making correct foresights about
        NFT projects. For more information, visit{" "}
        <a
          href="https://theseer.io"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          GitBook
        </a>
      </p>
      <p className="montserrat text-center w-11/12 md:w-6/12 mt-2 mx-auto">
        Making a foresight earns you 1 point. There are 2 type of foresight you
        can make.
      </p>
      <ul className="list-disc mx-auto montserrat mt-4">
        <li>
          <span className="font-bold">Making a foresight</span> - 1 Points
          point.
        </li>
        <li>
          <span className="font-bold">2 Day</span> - 3 Points for correct
          foresight
        </li>
        <li>
          <span className="font-bold">4 Day</span> - 6 Points for correct
          foresight
        </li>
      </ul>
      <table className="table-auto mx-auto mt-4 montserrat md:w-5/12">
        <thead className="font-bold">
          <tr>
            <th>Days</th>
            <th>Multipliers</th>
          </tr>
        </thead>
        <tbody className="text-center">
          <tr>
            <td>1-4 Days</td>
            <td>x1</td>
          </tr>
          <tr>
            <td>5-8 Days</td>
            <td>x1,4</td>
          </tr>
          <tr>
            <td>9-12 Days</td>
            <td>x1,9</td>
          </tr>
          <tr>
            <td>13-17 Days</td>
            <td>x2,5</td>
          </tr>
        </tbody>
      </table>
      <p className="montserrat text-center w-11/12 md:w-6/12 mx-auto mt-3">
        Average of multipliers is x1,7 so in this tool multiplier is set to 1,7x
        by default.
      </p>
      <p className="montserrat text-center w-11/12 md:w-6/12 mx-auto mt-3 text-red-500">
        The maximum number of days is 32, as the Seer can have a maximum of 2
        active foresights.
      </p>
      <div className="grid gap-6 mb-6 md:grid-cols-2 w-10/12 md:w-6/12 mx-auto montserrat mt-5">
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            2-Day Correct Foresight
          </label>
          <input
            type="number"
            value={C2}
            onChange={(e) => setC2(e.target.value)}
            onBlur={(e) => {
              if (e.target.value === "") setC2("0");
            }}
            className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5"
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            2-Day Wrong Foresight
          </label>
          <input
            type="number"
            value={W2}
            onChange={(e) => setW2(e.target.value)}
            onBlur={(e) => {
              if (e.target.value === "") setW2("0");
            }}
            className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5"
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            4-Day Correct Foresight
          </label>
          <input
            value={C4}
            onChange={(e) => setC4(e.target.value)}
            onBlur={(e) => {
              if (e.target.value === "") setC4("0");
            }}
            type="number"
            className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5"
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-900">
            4-Day Wrong Foresight
          </label>
          <input
            value={W4}
            onChange={(e) => setW4(e.target.value)}
            onBlur={(e) => {
              if (e.target.value === "") setW4("0");
            }}
            type="number"
            className="text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5"
          />
        </div>
      </div>
      <table className="table-auto mx-auto mt-4 montserrat md:w-3/12 my-52">
        <tbody className="">
          <tr>
            <td className={`font-bold`}>Total Days (Max 32)</td>
            <td
              className={`
             ${
               (Number(C2) + Number(W2)) * 2 + (Number(C4) + Number(W4)) * 4 >
               32
                 ? "text-red-500"
                 : "text-green-500"
             }`}
            >
              {(Number(C2) + Number(W2)) * 2 + (Number(C4) + Number(W4)) * 4}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Multiplier</td>
            <td>x1,7</td>
          </tr>
          <tr>
            <td className="font-bold">Points From Making Foresights</td>
            <td>{Number(C2) + Number(W2) + Number(C4) + Number(W4)}</td>
          </tr>
          <tr>
            <td>Total Points Without Multipliers</td>
            <td>
              {Number(C2) * 3 +
                Number(C4) * 6 +
                (Number(C2) + Number(W2) + Number(C4) + Number(W4))}
            </td>
          </tr>
          <tr>
            <td className="font-bold">Total Points With Multipliers</td>
            <td>
              {(
                (Number(C2) * 3 +
                  Number(C4) * 6 +
                  (Number(C2) + Number(W2) + Number(C4) + Number(W4))) *
                1.7
              ).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default App;
